import React ,{useEffect , useState} from 'react';
import {useNavigate , Link } from 'react-router-dom';
import axios from "axios";
import '.././Login.css';

function Login(){

  const [username, setUsername] = useState("")
  const [password, setpassword] = useState("")
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem('user_id')){
      navigate("/");
    }
 })

 const validate = () => {
  let errors = {};
  if (!username) errors.username = 'UserName is required';
  if (!password) errors.password = 'Password is required';

  setErrors(errors);
  return Object.keys(errors).length === 0;
};

 function submitdata(){
  if(validate()){
    const formdata = new FormData() 
    formdata.append('user_phone' , username)
    formdata.append('user_password' , password)

    axios.post('https://drfrnd.com/api.php?type=login' , formdata)
      .then((response)=>{
        console.log(response);
        if(response.data.status === 1){

          localStorage.setItem('user_id' , response.data.data.user_id);
          localStorage.setItem('name' , response.data.data.name);
          //window.location.reload();
          navigate("/");
        }else{
          alert(response.data.message);
        }
        // load_user();
      })
   }
  }


    return(
        <>
        <main className="main-wrapper">
        
        <div className="axil-shop-area axil-section-gap bg-color-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <div className="col-lg-6 offset-lg-3">
                      <div className="">
                        <div className="axil-signin-form">
                            <h3 className="title">Sign in to DrFrnd.</h3>
                            <p className="b2 mb--55">Enter your detail below</p>
                            <form className="singin-form">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="number" className="form-control" value={username} onChange={(e)=> setUsername(e.target.value)} />
                                    {errors.username && <div className="error">{errors.username}</div>}
                                </div>
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="password" className="form-control" value={password} onChange={(e)=> setpassword(e.target.value)}  />
                                    {errors.password && <div className="error">{errors.password}</div>}
                                </div>
                                <div className="form-group d-flex align-items-center justify-content-between">
                                    <button type="button" onClick={()=>submitdata()} className="axil-btn btn-bg-primary submit-btn">Sign In</button>
                                    <Link className="forgot-btn" to="/signup">Create a account?</Link>
                                    {/* <a href="forgot-password.html" className="forgot-btn">Forget password?</a> */}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
        </main>
        </>
    )
}

export default Login;