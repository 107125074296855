import React , {useState} from 'react';
import {useNavigate , Link} from 'react-router-dom';
import { useSelector , useDispatch } from 'react-redux';
import { addOrUpdateData } from '../Store/internalSlice';
import { Helmet } from 'react-helmet';

function Navbar(){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cartProduct = useSelector(state => state.cart);
    const commenData = useSelector(state => state.commen);
    const {currency_name} = useSelector((state) => state.internal);
    const [isToggled, setIsToggled] = useState(false);



   if (commenData.data === null) {
    return (
      <>
        <h1>Loading.........</h1>
      </>
    );
  }

  function addtolang(cu_id , currency_name){
        var currdata = {'id':cu_id , 'cur_name':currency_name};
        dispatch(addOrUpdateData(currdata));
  }
  function handleLogout() {
    // Clear user data from localStorage
    localStorage.removeItem('user_id');
    localStorage.removeItem('name');
    navigate("/login");
  };
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

   return(
    <>
       <header class="header axil-header header-style-5">
        
        <div id="axil-sticky-placeholder"></div>
        <div class="axil-mainmenu">
            <div class="container">
                <div class="header-navbar">
                    <div class="header-brand">

                        <Link to="/" className='logo logo-dark'><img src={commenData.data.domainlogo} alt="Site Logo" /></Link>
                        <Link to="/" className='logo logo-light'><img src={commenData.data.domainlogo} alt="Site Logo" /></Link>

                    </div>
                    <div class="header-main-nav">
                        <nav class="mainmenu-nav">
                            <button class="mobile-close-btn mobile-nav-toggler"><i class="fas fa-times"></i></button>
                            <div class="mobile-nav-brand">
                                <Link to="/" className='logo'> <img src={commenData.data.domainlogo} alt="Site Logo" /></Link>
                            </div>
                            <ul className="mainmenu">
                                <li>
                                    <Link to="/">Home</Link>        
                                </li>
                                <li class="menu-item-has-children" onClick={handleToggle}>
                                    <Link to="/">Category</Link>
                                    <ul className={isToggled ? 'axil-submenu activesubmenu' : 'axil-submenu'}>
                                        {
                                        commenData.data.all_category.map((items , kyes)=>
                                            <li>
                                                <Link to={`/singlecategory/${items.id}`}>{items.name}</Link>
                                            </li>
                                        )
                                        }
                                        
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/shop">Shop</Link>        
                                </li>
                                {
                                    localStorage.getItem('user_id') ? (
                                        <>
                                        <li className="hide_desk">
                                            <Link to="/profile"> Profile</Link>
                                        </li>
                                        <li className="hide_desk">
                                            <Link to="/profile"> Order</Link>
                                        </li>
                                        <li className="hide_desk">
                                            <Link onClick={handleLogout}> Logout</Link>
                                        </li>
                                        </>
                                        ) : (
                                            <>
                                            <li className="hide_desk">
                                                <Link to="/login"> Login</Link>
                                            </li>
                                            <li className="hide_desk">
                                                <Link to="/signup"> Signup</Link>
                                            </li>
                                            </>
                                        )
                                    }
                                <li>
                                    <Link to="/about">About</Link>        
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>        
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="header-action">
                        <ul class="action-list">

                            <li className="shopping-cart">
                                <Link to="/cart" className="cart-dropdown-btn">
                                    <span className="cart-count">{cartProduct.length}</span>
                                    <i className="flaticon-shopping-cart"></i>
                                </Link>
                            </li>
                            {
                              localStorage.getItem('user_id') ? (
                                <>
                                <li className="my-account hide_mobile">
                                    <Link to="/profile" className='navlinkcss'> Profile</Link>
                                </li>
                                <li className="my-account hide_mobile">
                                    <Link to="/profile" className='navlinkcss'> Order</Link>
                                </li>
                                <li className="my-account hide_mobile">
                                    <Link onClick={handleLogout} className='navlinkcss'> Logout</Link>
                                </li>
                                </>
                                ) : (
                                    <>
                                    <li className="my-account hide_mobile">
                                        <Link to="/login" className='navlinkcss'> Login</Link>
                                    </li>
                                    <li className="my-account hide_mobile">
                                        <Link to="/signup" className='navlinkcss'> Signup</Link>
                                    </li>
                                    </>
                                )
                            }
                            
                            <li>
                                <div class="header-top-dropdown">
                                <div class="dropdown">
                                    <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {currency_name}
                                    </button>
                                        {commenData.isLoading === false && (
                                            <ul class="dropdown-menu">
                                                {
                                                    commenData.data.currency.map((items , kyes)=>
                                                        <li>
                                                            <p className="dropdown-item" onClick={()=>addtolang(items.id , items.currency)} data-id={items.id}>{items.currency}</p>
                                                        </li>
                                                    )
                                                }
                                                
                                                
                                            </ul>
                                        )}
                                    
                                    
                                </div>
                            </div>
                            </li>


                            <li className="axil-mobile-toggle">
                                <button className="menu-btn mobile-nav-toggler">
                                    <i className="flaticon-menu-2"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </>
   )
}

export default Navbar;